import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import Loader from "../Animations/Loader";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { unsubscribe } from "../../ApiCalls/LoginAuthenticateApi/api_v1_login_validate";

function Unsub() {
    const [loading, setLoading] = useState(true);
    const cookies = new Cookies();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Unsubscribe | Ding";
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const accessToken = localStorage.getItem('token');
                const decoded = jwt_decode(accessToken);
                return decoded.userInfo['msisdn'];
            } catch (error) {
                navigate('/login?r=1', { state: { unsubState: false } });
                throw new Error("Failed to fetch data");
            }
        }

        fetchData()
            .then(msisdn => unsubApiCall(msisdn))
            .catch(() => navigate('/login?r=1', { state: { unsubState: false } }))
            .finally(() => setLoading(false));
    }, []);

    function unsubApiCall(msisdn) {
        unsubscribe({ "msisdn": msisdn, "channel": "web" })
            .then((res) => {
                if (res.data.eCode === 201 && res.data.description === "Package Unsubscribed") {
                    logout()
                    navigate('/login?r=1', { state: { unsubState: true } });
                } else {
                    logout()
                    navigate('/login?r=1', { state: { unsubState: false } });
                }
            })
            .catch(() => navigate('/login?r=1', { state: { unsubState: false } }))
    }

    function logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('ctx');
        localStorage.removeItem('dx');
        localStorage.removeItem('time');
        cookies.remove('uid');
        cookies.remove('rmsid');
        const nextYear = new Date();
        const currentD = new Date();
        nextYear.setFullYear(currentD.getFullYear() + 1);
        cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
        // localStorage.clear();
        // cookies.remove('uid_exp');
        // cookies.remove('uid');
        // cookies.remove('base');
        // cookies.remove('clickid');
        // cookies.remove('cpid');
        // cookies.remove('rmsid');
        // cookies.remove('source');
        // cookies.remove('tc');
        // cookies.remove('u');
    }

    return loading ? <Loader /> : null;
}

export default memo(Unsub);
